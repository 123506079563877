import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TextField from "@material-ui/core/TextField";
import { API_POST } from "../../services/api.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
// import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
//import Checkbox from "../../components/Checkbox/Checkbox";
import Chip from "@material-ui/core/Chip";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import InfoBox from "../../components/InfoIcon/InfoIcon";
import { getMappedTeamData } from "../common/helper";



const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function ProspectCampaign(props) {
  const {
    IsCampainChange,
    Campaigns,
    allProspects,
    selectedRows,
    user,
    onChange,
    IsShowDate,
    Query
  } = props;
  const [InputType, setInputType] = useState("");
  const [options] = useState(Campaigns);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));

  let notification = {
    open: false,
    message: "",
    color: "success",
  };
  const [Notify, setNotify] = useState(notification);

  // const handleChangeCampain = (Type, InputType, selectedProspects) => {
  //   let Isupdated = true;
  //   Isupdated = InputType ? true : alert("Please select Campaign");

  //   if (Type === "selectedAll" && Isupdated) {
  //     let result = window.confirm("Do you want to update all prospects");
  //     Isupdated = result ? result : false;
  //     Isupdated && updateCampaign(InputType, props.Query, "action/updateMany");
  //   } else {
  //     Isupdated = true;
  //     for (var row in selectedProspects) {
  //       let query = { _id: selectedProspects[row]._id };
  //      // updateCampaign(InputType, query, "action/update");
  //     // updateProspectDetails( query, "action/update");
  //     }
  //   }
  //   Isupdated &&
  //     setNotify({
  //       open: true,
  //       message: "Campain change successfully.",
  //       color: "success",
  //     });
  //   setTimeout(function() {
  //     props.handleClose();
  //     props.RemoveAllChecks(false);
  //   }, 3000);
  // };

  const getCampaignUsers = async () => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaignMembers",
        //Change according to roleid --only visible agent and field executive
        //con: { role: user && user.role ,cid:cid },
        con: { cid: InputType._id },
      });

      if (registerRequest && registerRequest.status == 200) {
        setAllUsers(registerRequest && registerRequest.data);
      }
    } catch (error) { }
  };

  const handleChangeCampain = (Type, InputType, selectedProspects) => {
    debugger;
    let Isupdated = true;
    Isupdated = InputType ? true : alert("Please select Campaign");

    if (!Isupdated) return;

    if (Type === "selectedAll" && Isupdated) {
      let result = window.confirm("Do you want to update all prospects");
      Isupdated = result ? result : false;
      Isupdated && updateProspectDetails("updateMany", selectedProspects);
    } else {
      Isupdated = true;
      Isupdated && updateProspectDetails("update", selectedProspects);
    }
    Isupdated &&
      setNotify({
        open: true,
        message: "Data Change successfully.",
        color: "success",
      });

    if (onChange) {
       onChange();
    }

    setTimeout(function () {
      props.handleClose();
      props.RemoveAllChecks(false);
    }, 3000);
  };

  const updateProspectDetails = async (url, selectedProspects) => {
    let registerRequest;
    let body = [
      { cid: InputType._id },
      { assignTo: selectedUser.username },
      { admin: user.admin },
    ];
    registerRequest = await API_POST("action/updateProspectDetails", {
      selectedProspects: (url == "updateMany" ? null : selectedProspects),
      body,
      updatedType: url,
      Query
    });
    debugger;
    if (onChange) {
      onChange();
   }
    if (registerRequest && registerRequest.status == 200) {
      //props.allProspects();
    }
  };

  // const updateCampaign = async (campaign, query, url) => {
  //   let registerRequest;
  //   let body = { cid: campaign._id };
  //   registerRequest = await API_POST(url, {
  //     root: "prospects",
  //     body,
  //     querydata: query,
  //   });

  //   if (registerRequest && registerRequest.status == 200) {
  //   }
  // };

  useEffect(() => {
    if (InputType) {
      setSelectedUser("");
      getCampaignUsers();
    }
  }, [InputType]);

  return (
    <>
      <Snackbar
        place="tr"
        color={Notify.color}
        message={Notify.message}
        open={Notify.open}
        autoHideDuration={3000}
        closeNotification={() => setNotify({ open: false })}
        close
      />

      <GridContainer>
        <Dialog open={IsCampainChange} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true}>
          <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
            Shift Prospect to other Campaign
          </DialogTitle>

          <DialogActions>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Autocomplete
                  onChange={(event, value) => setInputType(value)}
                  id="combo-box-demo"
                  size="small"
                  options={options}
                  labelText="FieldType"
                  name="autoComplete"
                  value={InputType || null}
                  getOptionLabel={(option) => option.name || ""}
                  defaultValue="Select"
                  renderTags={(value) =>
                    value.map(() => (
                      <Chip variant="outlined" label="Enter Field" />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Campaign "
                      variant="outlined"
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <br />
                <Autocomplete
                  onChange={(event, value) => setSelectedUser(value)}
                  id="combo-box-demo"
                  options={allUsers}
                  size="small"
                  labelText="FieldType"
                  name="autoComplete"
                  value={selectedUser || null}
                  getOptionLabel={(allUsers) => allUsers.username+"("+getMappedTeamData(allUsers.username)+")" || ""}
                  defaultValue="Select"
                  renderTags={(value) =>
                    value.map(() => (
                      <Chip variant="outlined" label="Enter Field" />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select User"
                      variant="outlined"
                    />
                  )}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <Button
                  size="sm"
                  onClick={() => {
                    handleChangeCampain("selected", InputType, selectedRows);
                  }}
                  color="primary"
                >
                  Selected - {selectedRows.length > 0 ? selectedRows.length : 0}
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    handleChangeCampain("selectedAll", InputType, allProspects);
                  }}
                  color="primary"
                >
                  Selected All - {props.totalRowCount ? props.totalRowCount : 0}
                </Button>
                <GridItem style={{float: "right", marginTop :"10px"}}>
                  <InfoBox name={'Schedule.Follwups.Update'} />
                </GridItem>
                {/* <Button size="sm" onClick={props.handleClose} color="primary">
                  Close
                </Button> */}
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </>
  );
}

ProspectCampaign.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ProspectCampaign);
