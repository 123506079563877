import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import { API_GET, API_POST } from "../../services/api.service";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import Filters from "./Filter";
import Button from "components/CustomButtons/Button.jsx";


import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown.js';
import HighchartsReact from 'highcharts-react-official';
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import ErrorBoundary from "../../hoc/ErrorBoundary";
import Followups from "../Schedule/Followups";
import Appointment from "../Schedule/Appointment";
import ReportsStats from "../ReportStats/reportsStats";
import Files from "../Campaigns/Files";
import ProspectHistory from "../ProspectHistory/ProspectHistory";
import ApiLog from "./ApiLog";

//import _ from "underscore";

const filterquery = { $and: [{ $or: [{ IsActive: { $exists: false } }, { IsActive: true }] }] };
let mysq = {};


const chartOption = {
  "title": {
    "text": "WorkLoad"
  },
  "tooltip": {
    "pointFormat": "<span style=\"color:{point.color};\">{series.name}</span>: <b>{point.y}</b>",
    "backgroundColor": "white"
  },
  "chart": {
    "type": "pie",
  },
  "plotOptions": {
    "series": {
      "cursor": "pointer",
      "dataLabels": {
        "color": "#428bca",
        "distance": -30,
        "enabled": true,
        "backgroundColor": "white",
        "format": "{point.percentage:.1f}%"
      },
      "showInLegend": true,
      "allowPointSelect": true
    }
  },
  "series": [
    {
      "name": "Workload",
      "id": "Workload",
      "colorByPoint": true,
      "data": null
    }
  ]
};

function SystemLog(props) {
  const { user } = props;
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [disposition, setDisposition] = useState([]);
  let dispositionColumnDone = 0;


  const loadWorkloadData = async () => {


    let res = await API_GET("action/AggregateReport", null, {
      admin: user && user.admin
    });

    let data = res.data;




    setData(data || []);



    //setChartOption(_chartOption);
  };

  const columns = [
    {
      name: "User",
      //selector: "username",
      width: '220px',
      selector: row => row.username,
    },

    {
      name: "Assigned Prospects",
      //selector: "assigned",
      cell: (row) => (
        <>
          {
            row.username != 'Total' ? (
              <Link to={'/admin/ListProspects?query=' + btoa(JSON.stringify({ $and: [{ $or: [{ IsActive: { $exists: false } }, { IsActive: true }] }, { assignTo: row.username }] }))}>
                {row.assigned}</Link>
            ) : row.assigned
          }
        </>
      ),
    },

    {
      name: "Attempted",
      //selector: "attempt",
      cell: (row) => (
        <>
          {
            row.username != 'Total' ? (
              <Link to={'/admin/ListProspects?query=' + btoa(JSON.stringify({ $and: [{ $or: [{ IsActive: { $exists: false } }, { IsActive: true }] }, { assignTo: row.username }], disposition: { $ne: null } }))}>
                {row.attempt}</Link>
            ) : row.attempt
          }
        </>
      ),
    },
    {
      name: "Not Attempted",
      //selector: "notAttempt",
      cell: (row) => (
        <>
          {
            row.username != 'Total' ? (
              <Link to={'/admin/ListProspects?query=' + btoa(JSON.stringify({ $and: [{ $or: [{ IsActive: { $exists: false } }, { IsActive: true }] }, { assignTo: row.username }], $or: [{ disposition: { $exists: false } }, { disposition: null }] }))}>
                {row.notAttempt}</Link>
            ) : row.notAttempt
          }
        </>
      ),
    },

    {
      name: "Open",
      //selector: "open",
      cell: (row) => (
        <>
          {
            row.username != 'Total' ? (
              <Link to={'/admin/ListProspects?query=' + btoa(JSON.stringify({ $and: [{ IsActive: true }, { assignTo: row.username }], open: true }))}>
                {row.open}</Link>
            ) : row.open
          }
        </>
      ),
    },

    {
      name: "Close",
      //selector: "close",
      cell: (row) => (
        <>
          {
            row.username != 'Total' ? (
              <Link to={'/admin/ListProspects?query=' + btoa(JSON.stringify({ $and: [{ $or: [{ IsActive: { $exists: false } }, { IsActive: true }] }, { assignTo: row.username }], open: false }))}>
                {row.close}</Link>
            ) : row.close
          }
        </>
      ),
    },
    {
      name: "Upcoming Callbacks",
      //selector: "followups",
      cell: (row) => (
        <>
          {
            row.username != 'Total' ? (
              <Link to={'/admin/ListProspects?query=' + btoa(JSON.stringify({ IsActive: true, assignTo: row.username, callback: { $gte: new Date() } }))}>
                {row.followups}</Link>
            ) : row.followups
          }
        </>
      ),
    },
    {
      name: "Overdue Callbacks",
      //selector: "overdue",
      cell: (row) => (
        <>
          {
            row.username != 'Total' ? (
              <Link to={'/admin/ListProspects?query=' + btoa(JSON.stringify({ IsActive: true, assignTo: row.username, callback: { $lt: new Date() } }))}>
                {row.overdue}</Link>
            ) : row.overdue
          }
        </>
      ),
    },


  ];
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    setActiveTab(parseInt(tab));

    if (user && user.admin) {
      loadWorkloadData();
    }
  }, [user]);

  const getColName = (selector) => {
    for (let index = 0; index < columns.length; index++) {
      const element = columns[index];
      if (element.selector == selector) {
        return element.name;
      }

    }
  }

  const bindChart = () => {

    let _chartOption = { ...chartOption };
    _chartOption.series[0]['data'] = null
    let obj = [];
    for (const key in data.TotalInfo) {
      if (Object.hasOwnProperty.call(data.TotalInfo, key)) {
        const element = data.TotalInfo[key];
        if (!isNaN(element)) {
          obj.push({
            "y": element,
            "name": getColName(key),
            "id": key,
          })
        }
      }
    }
    _chartOption.series[0]['data'] = obj

    return _chartOption;
  }

  const handleChange = (event, value) => {
    const { history } = props;
    setActiveTab(value);
    history.push('?tab=' + value)
  };

  return (
    <>
      <>



        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            TabProps={{
              onChange: handleChange,
            }}
            selectedValue={activeTab}
            headerColor="info"
            tabs={[
              {
                tabName: "Files",
                tabContent: (
                  <>
                    <CardBody>
                      <Files props={props} user={props.user} />
                    </CardBody>
                  </>
                ),
              },
              {
                tabName: "Prospect Log",
                tabContent: (
                  <CardBody>
                    <ProspectHistory props={props} user={props.user} />

                  </CardBody>
                ),
              },
              {
                tabName: "API Log",
                tabContent: (
                  <CardBody>

                    <ApiLog props={props} user={props.user} />
                  </CardBody>
                ),
              },
            ]}
          />
        </GridItem>



      </>
    </>
  );
}

export default SystemLog;


