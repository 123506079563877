import React, { useEffect, useState } from 'react';
import { Select, MenuItem, InputLabel, FormControl, Checkbox, ListItemText } from '@material-ui/core';


const CommTypeDropdown = ({ value, onChange, multiple = true, label = "Communnication Type" }) => {
  const [communicationTypeOption,setcommunicationTypeOption]=useState([

    {
      key:"INCOMING",
      value:"incoming"
    },
    {
      key:"OUTGOING",
      value:"outgoing"
    },
    {
      key:"MISSED",
      value:"missed"
    }
  ]);


  const handleChange = (event) => {
    const selectedValues = event.target.value;
    onChange(selectedValues); // Pass the updated array of selected values
  };

  return (
    <FormControl fullWidth variant="outlined" >
      <InputLabel shrink={true} id='commtype-list-dropdown-label'>{label}</InputLabel>
      <Select
        label={label}
        value={multiple ? (Array.isArray(value) ? value : []) : value || ""} // Ensure value is an array for multi-select
        onChange={handleChange}
        
        labelId="commtype-list-dropdown-label"
        id="commtype-list-dropdown"
        multiple={multiple} // Enables multi-select when true
        renderValue={(selected) =>
          multiple
            ? communicationTypeOption
              .filter((user) => selected.includes(user.key))
              .map((user) => user.value)
              .join(', ')
            : communicationTypeOption.find((user) => user.key === selected).value || "" // Safely handle single select
        }
      >
        {communicationTypeOption.map((user) => (
          <MenuItem key={user.key} value={user.key}>
            {multiple && <Checkbox checked={value.includes(user.key)} />}
            <ListItemText primary={user.value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CommTypeDropdown;