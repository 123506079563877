import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

// import ReactPaginate from 'react-paginate';
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import DataTableMobileStyle from "assets/jss/material-dashboard-react/components/DataTableMobileStyle.jsx";
import Button from "../CustomButtons/Button";
import TemplatePopup from "../../views/CommTemplates/TemplatePopup";

class GridRowClass extends React.Component {
  state = {
    value: 0
  };

  render() {
    const {

      columns,
      row,
      user,
      history,
    } = this.props;
    let inHeader = false;
    let inFooter = false;
    let usedKeys = ["email", "phone", "caller", "duration", "type", "startDate", "endDate", "admin", "phone", "pid", "admin"];
    //console.log("ssssssss", columns, row)
    let columnsn = columns.map((v) => { usedKeys.includes(v.name) });

    for (let index = 0; index < columnsn.length; index++) {
      const col = columns[index];
      if (col.inHeader) {
        inHeader = true;
      }
      if (col.inAction) {
        inFooter = true;
      }
    }


    return (
      <Card className="datatableresponsive">
        {inHeader && <CardHeader>
          <ul>
            {
              columns && columns.map((col, key) => {
                if (col.inHeader) {
                  if (col.cell) {
                    return <li><label>{col.name}</label> <span>{col.cell(row)}</span></li>
                  }
                  else {
                    return <li><label>{col.name}</label> <span>{row[col.selector]}</span></li>
                  }
                }
              })
            }
          </ul>
        </CardHeader>}
        <CardBody style={{ padding: "5px 0px" }}>
          <GridContainer>



            <GridItem xs={12} sm={12} md={12}>

              <GridContainer>
                <GridItem xs={9} sm={9} md={9}>
                  <h3 style={{ padding: "0px", margin: '5px 0px' }}>
                    <span style={{ fontSize: "24px", paddingLeft: "0px", margin: '0px' }} onClick={(e) => { row.pid && this.props.history.push("/admin/ViewProspect/" + row.pid) }}> {row['phone']} </span>

                  </h3>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <Button
                    color={row.pid ? "success" : "danger"}
                    style={{ marginLeft: "15%" }}
                    size="sm"
                    onClick={(e) => { row.pid ? this.props.history.push("/admin/ViewProspect/" + row.pid) : this.props.setOpenAddProspect(true) }}
                  //aria-label="small outlined button group"
                  >
                    {row.pid ? "Business" : "Personal"}

                  </Button>
                </GridItem>
              </GridContainer>



              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Type - {row['type']}
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    Duration :  {new Date((row.duration ? row.duration : 0) * 1000)
                      .toISOString()
                      .substr(11, 8)}
                  </GridItem>
                </GridContainer>

              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer style={{ fontSize: "10px" }}>
                  <GridItem xs={6} sm={6} md={6}>
                    Start -  {row.startDate &&
                      window.moment(row.startDate).format("DD-MMM-YYYY HH:mm:ss")}
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    End - {row.endDate &&
                      window.moment(row.endDate).format("DD-MMM-YYYY HH:mm:ss")}
                  </GridItem>
                </GridContainer>

              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer style={{ fontSize: "12px" }}>
                  <GridItem xs={12} sm={12} md={12}>
                    Caller : {row['caller']}
                  </GridItem>

                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>



                <div style={{ fontSize: "25px" }}>
                  {!row.pid && <i onClick={(e) => this.props.setOpenAddProspect(true)} style={{ margin: "5px", color: "#00acc1" }} class="fa fa-solid fa-user-plus" aria-hidden="true"></i>

                  }
                  {row.pid && <i onClick={(e) => {
                    this.props.history.push("/admin/ViewProspect/" + row.pid);
                  }} style={{ margin: "5px", color: "green" }} class="fa fa-solid fa-phone"></i>

                  }

                  <TemplatePopup
                    user={user}
                    pid={row.pid}
                    prospects={row}
                    type="email"
                  />
                  <TemplatePopup
                    user={user}
                    pid={row.pid}
                    prospects={row}
                    type="sms"
                  />
                  <TemplatePopup
                    user={user}
                    pid={row.pid}
                    prospects={row}
                    type="whatsapp"
                  />


                </div>

              </GridItem>
            </GridItem>

            {
              columns && columns.map((col, key) => {
                if (!col.inHeader && !col.inAction) {
                  if (col.cell) {
                    // return <GridItem xs={12} sm={6} md={3}><label>{col.name}</label> <span>{col.cell(row)}</span></GridItem>
                  }
                  else {
                    // return <GridItem xs={12} sm={6} md={3}><label>{col.name}</label> <span>{row[col.selector]}</span></GridItem>
                  }
                }
              })
            }
          </GridContainer>
        </CardBody>
        {inFooter && <CardFooter>
          <ButtonGroup
            color="info"
            aria-label="small outlined button group"
          >

            {
              columns && columns.map((col, key) => {
                if (col.inAction) {
                  if (col.cell) {
                    return col.cell(row)
                  }

                }
              })
            }


          </ButtonGroup>
        </CardFooter>}
      </Card>

    )
  }
}

GridRowClass.propTypes = {

  columns: PropTypes.array,
  row: PropTypes.array,

}

export default withStyles(DataTableMobileStyle)(GridRowClass);
