import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

// import ReactPaginate from 'react-paginate';
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import _ from "underscore";

import Avatar from "@material-ui/core/Avatar";

import Collapse from "@material-ui/core/Collapse";


import DataTableMobileStyle from "assets/jss/material-dashboard-react/components/DataTableMobileStyle.jsx";
import DataTableMobileRow from "./DataTableMobileRow";
import GridRowClass from "./GridRow";
import { Box } from "@material-ui/core";


function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {

  name = name.toUpperCase().trim();
  let firstChar = name.split(' ')[0][0];
  let secondChar = '';

  if (name.split(' ').length > 1) {
    secondChar = name.split(' ')[1][0];
  }




  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${firstChar}${secondChar}`,
  };
}

function formatDate(date) {

  if (date) {
    let mdate = window.moment(date)



    return mdate.fromNow()
  }
  else {
    return 'not yet';
  }
}
function callbackdate(date) {
  if (date) {
    let mdate = window.moment(date)


    return mdate.format('YYYY-MM-DD HH:mm')
  }
  else {
    return 'callback not set';
  }
}

function showdisposition(value) {
  let arr = [];
  if (value.dispositionArray && value.dispositionArray.length > 0) {
    for (
      let index = 0;
      index < value.dispositionArray.length;
      index++
    ) {
      const element = value.dispositionArray[index];
      arr.push(element.disposition_value);
    }
  }

  return arr.join(" > ");
}








class DataTableMobile extends React.Component {
  state = {
    value: 0,
    PageNumber: 1
  };

  onSelectedRowsChange(row, checked) {
    let { data } = this.props;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element._id === row._id) {
        data[index]["checked"] = checked;
        break;
      }
    }
    let selectedRows = _.where(data, { checked: true });

    this.props.onSelectedRowsChange({ selectedRows });
  }

  render() {
    const {
      checkboxes,
      selectableRows,
      columns,
      data,
      user,
      paginationTotalRows,
      paginationPerPage,
      onChangePage,
      onSelectedRowsChange,
      clearSelectedRows,
      newGrid = false
    } = this.props;
    const {
      PageNumber
    } = this.state;

    let nextPage = PageNumber + 1;
    let prevPage = PageNumber - 1;
    let lastPage = Math.ceil(paginationTotalRows / paginationPerPage);

    return (
      <>
        {
          data && data.map((row, key) => {
            return <>
              {
                newGrid ? <GridRowClass user={user} history={this.props.history} row={row} columns={columns} setOpenAddProspect={this.props.setOpenAddProspect}
                  onSelectedRowsChange={this.onSelectedRowsChange.bind(this)}
                /> : <DataTableMobileRow user={user} row={row} columns={columns}
                  onSelectedRowsChange={this.onSelectedRowsChange.bind(this)}
                />
              }

            </>
          })

        }
        <CardFooter >
          <Box sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#f8f9fa',
            boxShadow: '0 -2px 4px rgba(0,0,0,0.1)',
            textAlign: 'center',
            padding: '10px',
          }}>
            <ButtonGroup >
              <Button disabled={PageNumber == 1 ? true : false} onClick={() => onChangePage(1)}>{'|<'}</Button>
              <Button disabled={PageNumber == 1 ? true : false} onClick={() => {
                this.setState({ PageNumber: prevPage })
                onChangePage(prevPage)
              }}>{'<'}</Button>
              <Button> {PageNumber} - {paginationPerPage} of {paginationTotalRows}
              </Button>

              <Button disabled={PageNumber == lastPage ? true : false} onClick={() => {
                this.setState({ PageNumber: nextPage })
                onChangePage(nextPage)
              }} >{'>'}</Button>
              <Button disabled={PageNumber == lastPage ? true : false} onClick={() => {
                this.setState({ PageNumber: lastPage })
                onChangePage(lastPage)
              }} >{'>|'}</Button>
            </ButtonGroup>
          </Box>
        </CardFooter>

      </>
    );
  }
}

DataTableMobile.propTypes = {
  checkboxes: PropTypes.bool,
  selectableRows: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  // highlightOnHover
  // pagination
  // paginationServer
  paginationTotalRows: PropTypes.number,
  paginationPerPage: PropTypes.number,

  onChangePage: PropTypes.func,// {handleChangePage}
  onSelectedRowsChange: PropTypes.func,
  clearSelectedRows: PropTypes.func
}

export default withStyles(DataTableMobileStyle)(DataTableMobile);
