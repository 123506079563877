import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TextField from "@material-ui/core/TextField";
import { API_POST } from "../../services/api.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";


import Chip from "@material-ui/core/Chip";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import DialogTitle from "../../components/DialogTitle/DialogTitle";

const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function ProspectFresh(props) {
  debugger;
  const { cid, IsAssignment, allProspects, selectedRows, onChange, user } = props;

  const [Notify, setNotify] = useState({
    open: false,
    message: "",
    color: "success",
  });
  const [allUsers, setAllUsers] = useState([]);
  let [InputType, setInputType] = useState("");

  const getCampaignUsers = async () => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaignMembers",
        //Change according to roleid --only visible agent and field executive
        //con: { role: user && user.role ,cid:cid },
        con: { cid: cid },
      });

      if (registerRequest && registerRequest.status == 200) {
        setAllUsers(registerRequest && registerRequest.data);
      }
    } catch (error) { }
  };

  const handleSetAssignment = (Type, InputType, selectedProspects) => {
    debugger;
    if (Type != "selectedAll" && selectedProspects.length == 0) {
      alert("Please select Prospects");
      return;
    }

    let Isupdated = true;
    Isupdated = true;

    console.log(Type, Isupdated)

    if (Type === "selectedAll" && Isupdated == true) {
      let result = window.confirm("Do you want to update all prospects");
      Isupdated = result ? result : false;
      Isupdated && updateAssignment(InputType, props.Query, props.Query);
      // InsertAssignmentHistory(InputType, props.Query);
    } else {
      Isupdated = true;
      for (var row in selectedProspects) {
        let query = { _id: selectedProspects[row]._id };
        let query2 = { pid: selectedProspects[row]._id };

        updateAssignment(InputType, query, query2);
        // InsertAssignmentHistory(InputType, selectedProspects[row]);
      }

      Isupdated && setNotify({
        open: true,
        message: "Fresh successfully",
        color: "success",
      });

      if (onChange) {
        onChange();
      }

      setTimeout(function () {
        props.handleClose();
        props.RemoveAllChecks(false);
      }, 3000);
    }
  };

  const updateAssignment = async (User, query, query2) => {
    let registerRequest;

    registerRequest = await API_POST("action/updateMany", {
      root: "prospects",
      body: { 
        $unset: { disposition: 1, dispositionArray: 1, callback: 1, LastCalled: 1, tags:1, notifyme:1 },
        $set: { assignTo: '', assignedBy: '', callDialed: false, open: true, Priority:0 }
      },
      querydata: query,
    });
    // registerRequest = await API_POST("action/updateMany", {
    //   root: "prospects",
    //   body: { assignTo: '', assignedBy: '', callDialed: false, open: true },
    //   querydata: query,
    // });




    if (registerRequest && registerRequest.status == 200) {
      console.log("Change successfully");
      setNotify({
        open: true,
        message: "prospect changed to fresh",
        color: "success",
      });
    }
  };

  useEffect(() => {
    getCampaignUsers();
  }, []);

  return (
    <>
      <Snackbar
        place="tr"
        color={Notify.color}
        message={Notify.message}
        open={Notify.open}
        autoHideDuration={3000}
        closeNotification={() => setNotify({ open: false })}
        close
      />

      <GridContainer>
        <Dialog open={IsAssignment} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true}>
          <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
            Make Prospects as new
          </DialogTitle>

          <DialogActions>
            <GridContainer>

              <GridItem xs={12} sm={12} md={12}>
                <Button
                  size="sm"
                  onClick={() => {
                    handleSetAssignment("selected", InputType, selectedRows);
                  }}
                  color="primary"
                >
                  Selected - {selectedRows.length > 0 ? selectedRows.length : 0}
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    handleSetAssignment("selectedAll", InputType, allProspects);
                  }}
                  color="primary"
                >
                  Selected All - {props.totalRowCount ? props.totalRowCount : 0}
                </Button>
                {/* <Button onClick={props.handleClose} color="primary">
                  Close
                </Button> */}
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </>
  );
}

ProspectFresh.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ProspectFresh);
