import { makeStyles } from "@material-ui/core/styles";



import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import UserListDropdown from "../../components/common/UserListDropdown"; // Import the user dropdown component
import CommTypeDropdown from "../../components/common/CommTypeDropdown"; // Import the user dropdown component
import FilterDropdown from "../../components/common/FilterDropdown"; // Import the user dropdown component

const useStyles = makeStyles({
  inputPadding: {
    "& .MuiOutlinedInput-input": {
      padding: 8,
    },
  },
});
const mongoOperators = [
  { label: "EQUAL", value: "$eq" },
  { label: "NOT EQUAL", value: "$ne" },
  { label: "GREATER THAN", value: "$gt" },
  { label: "LESS THAN", value: "$lt" },
  { label: "GREATER OR EQUAL TO", value: "$gte" },
  { label: "LESS OR EQUAL TO", value: "$lte" },
  { label: "CONTAINS", value: "contains" },
  { label: "IN", value: "$in" },
  { label: "NOT IN", value: "$nin" },
  { label: "EXISTS", value: "$exists" },

];

const logicalOperators = ["AND", "OR"];

const FilterPopup = ({ name, open, onClose, onApply, columns, user }) => {
  const classes = useStyles();
  const [filters, setFilters] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const FILTERS_STORAGE_KEY = "filter_popup_state_" + name + "_" + user.id;

  useEffect(() => {
    const savedFilters = localStorage.getItem(FILTERS_STORAGE_KEY);
    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
      onApply(savedFilters);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filters));
  }, [filters]);

  const addFilter = () => {
    setFilters([
      ...filters,
      { field: "", operator: "$eq", value: "", logic: "AND", fieldType: "text" },
    ]);
  };

  const updateFilter = (index, key, value) => {
    const updatedFilters = [...filters];
    updatedFilters[index][key] = value;

    if (key === "field") {
      const selectedColumn = columns.find((col) => col.field === value);
      if (selectedColumn) {
        updatedFilters[index].fieldType = selectedColumn.fieldType || "text";
        updatedFilters[index].operator = selectedColumn.defaultOperator || "$eq";

      }
    }

    setFilters(updatedFilters);
  };

  const removeFilter = (index) => {
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
  };

  const handleApplyFilters = () => {
    onApply(filters);
    onClose();
  };

  const renderValueInput = (filter, index) => {
    switch (filter.fieldType) {
      case "number":
        return (
          <TextField
            type="number"
            label="Value"
            value={filter.value}
            variant="outlined"
            onChange={(e) => updateFilter(index, "value", e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        );
      case "date":
        return (
          <TextField
            type="date"
            label="Date"
            variant="outlined"
            value={filter.value}
            onChange={(e) => updateFilter(index, "value", e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        );
      case "user":
      case "users":
      case "caller":
        return (
          <UserListDropdown
            value={filter.value}
            onChange={(newValue) => updateFilter(index, "value", newValue)}
            user={user}
          />
        );
      case "commType":
        return (
          <CommTypeDropdown
            value={filter.value}
            onChange={(newValue) => updateFilter(index, "value", newValue)}

          />
        );
      case "commType":
        return (
          <FilterDropdown
            value={filter.value}
            onChange={(newValue) => updateFilter(index, "value", newValue)}
            Options={filter.options}
          />
        );
      case "text":
      default:
        return (
          <TextField
            label="Value"
            variant="outlined"
            value={filter.value}
            onChange={(e) => updateFilter(index, "value", e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        style: isMobile
          ? { padding: 10, minHeight: "100vh" }
          : { padding: 20, maxHeight: "80vh" },
      }}
      className={classes.inputPadding}
    >
      <DialogTitle>Advance Filters

        <Button onClick={onClose} color="primary" style={{ float: "right" }}>Close</Button>
      </DialogTitle>
      <DialogContent dividers>
        {filters.map((filter, index) => (
          <Grid container spacing={isMobile ? 1 : 2} alignItems="center" key={index}>
            <Grid item xs={12} sm={3}>
              <Select
                value={filter.field}
                onChange={(e) => updateFilter(index, "field", e.target.value)}
                fullWidth
                variant="outlined"
              >
                {columns.map(
                  (col) =>
                    col.filterable &&
                    (!col.role || col.role.includes(user.role)) && (
                      <MenuItem key={col.field} value={col.field}>
                        {col.name}
                      </MenuItem>
                    )
                )}
              </Select>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Select
                value={filter.operator}
                onChange={(e) => updateFilter(index, "operator", e.target.value)}
                fullWidth
                variant="outlined"
              >
                {mongoOperators.map((op) => (
                  <MenuItem key={op.value} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              {renderValueInput(filter, index)}
            </Grid>
            <Grid item xs={6} sm={2}>
              <Select
                value={filter.logic}
                onChange={(e) => updateFilter(index, "logic", e.target.value)}
                fullWidth
                variant="outlined"
              >
                {logicalOperators.map((op) => (
                  <MenuItem key={op} value={op}>
                    {op}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6} sm={1}>
              <Tooltip title="Remove Filter">
                <IconButton onClick={() => removeFilter(index)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>

          </Grid>

        ))}

      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={addFilter}
          startIcon={<AddIcon />}
        >
          Add Filter
        </Button>
        <Button variant="contained" color="primary" onClick={handleApplyFilters}>
          Apply Filters
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterPopup;