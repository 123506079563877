import React, { useEffect, useState } from 'react';
import { Select, MenuItem, InputLabel, FormControl, Checkbox, ListItemText } from '@material-ui/core';
import { API_GET } from '../../services/api.service';

const UserListDropdown = ({ value, onChange, user, multiple = true, label = "Users" }) => {
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (user && ["manager", "owner"].includes(user.role)) {
      (async () => {
        try {
          const userInfo = await API_GET(`register/UserInfoByUserName?username=${user.admin}`);
          if (userInfo.success && userInfo.data.all_members) {
            const userOptions = userInfo.data.all_members
              .filter((element) => element.is_active)
              .map((element) => ({
                key: element.username,
                value: `${element.username} (${element.first_name})`,
              }));
            setAllUsers(userOptions);
          }
        } catch (error) {
          console.error('Failed to fetch users:', error);
        }
      })();
    }
  }, [user]);

  const handleChange = (event) => {
    const selectedValues = event.target.value;
    onChange(selectedValues); // Pass the updated array of selected values
  };

  return (
    <FormControl fullWidth variant="outlined" >
      <InputLabel shrink={true} id='user-list-dropdown-label'>{label}</InputLabel>
      <Select
        label={label}
        value={multiple ? (Array.isArray(value) ? value : []) : value || ""} // Ensure value is an array for multi-select
        onChange={handleChange}
        
        labelId="user-list-dropdown-label"
        id="user-list-dropdown"
        multiple={multiple} // Enables multi-select when true
        renderValue={(selected) =>
          multiple
            ? allUsers
              .filter((user) => selected.includes(user.key))
              .map((user) => user.value)
              .join(', ')
            : allUsers.find((user) => user.key === selected).value || "" // Safely handle single select
        }
      >
        {allUsers.map((user) => (
          <MenuItem key={user.key} value={user.key}>
            {multiple && <Checkbox checked={value.includes(user.key)} />}
            <ListItemText primary={user.value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UserListDropdown;