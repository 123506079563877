import React, { useEffect, useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Loader from "components/Loader/Loader.jsx";
import TextField from '@material-ui/core/TextField';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';

import { API_POST } from "../../services/api.service";
import CallbackControl from "./CallbackControl";


import _ from "underscore";
import { element } from "prop-types";
import { Box } from "@material-ui/core";
import { BorderStyle } from "@material-ui/icons";

const style = {
  disposition: {
    display: 'inline-block',
    width: '100%',
    listStyle: 'none',
    margin: '3px 0'
  },
  dispositionActions: {
    float: 'right'
  },
  dispositionElement: {
    // border: '1px solid #27521a',
    padding: '6px 5px'
  },
  label: {
    color: '#27521a'
  }

};




function CreateControl(props) {
  const { item, onSelect, showControls, classes,campaign } = props;

  const [show, setShow] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  //const [, setChildDisposition] = useState([]);


  const loadCustomFields = async () => {
    debugger;
    let registerRequest;

    if (localStorage.getItem("allCustomFields") === null) {

    }
    else {
      let allCustomFields = JSON.parse(localStorage.getItem("allCustomFields"))

      let CustomFields = []

      for (let index = 0; index < item.form_fields.length; index++) {
        const element = item.form_fields[index];
        debugger;
        let obj = _.where(allCustomFields, { _id: element });
        if (obj && obj.length > 0)
          CustomFields.push(obj[0]);
      }

      //let CustomFields = _.where(allCustomFields, { parentId: id });

      setCustomFields(CustomFields);
      return;
    }

    try {
      registerRequest = await API_POST("action/getDispositionFields",
        {
          ids: item.form_fields
        });

      if (registerRequest && registerRequest.status == 200) {
        setCustomFields(registerRequest && registerRequest.data);
      }
    } catch (error) {
      console.log(error)
      //registerRequest = response;
    }
  }

  const checkAllParent = async (element) => {
    let parent = document.getElementById('rdo' + element.getAttribute("parent"));
    if (parent) {
      parent.checked = true;
      checkAllParent(parent);
    }
    return;
  }

  const handleSelect = async (e) => {
    setShow(true);
    let selected = [];
    if (item.form_fields && item.form_fields.length > 0) {
      if (customFields && customFields.length === 0) {
        await loadCustomFields();
      }
    }
    try {

      let frm = document.getElementById("frmDisposition");
      document.querySelectorAll(".rdoDisposition:checked").forEach(element => {
        element.checked = false;
      });

      document.querySelectorAll("input[name=disposition-null]").forEach(element => {
        if (element.parentElement.parentElement.innerHTML.indexOf(e.target.id) > -1) {
          if (e.target.parentElement.nextElementSibling) {
            e.target.parentElement.nextElementSibling.classList.remove("hide");
            e.target.parentElement.nextElementSibling.classList.add("active");
          }
          e.target.checked = true;
          checkAllParent(document.getElementById(e.target.id));
        }
        else {
          if (element.parentElement.nextElementSibling) {
            element.parentElement.nextElementSibling.classList.add("hide");
            element.parentElement.nextElementSibling.classList.remove("active");
          };

        }
      });

      document.querySelectorAll(".rdoDisposition:checked").forEach(element => {
        selected.push(element.value);
      });

    }
    catch (e) {
      console.log(e);
    }
    onSelect({ e, item, selected })
    setShow(false);
  }

  const handleChange = async (e) => {
    let formValue = {};

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    }
    else if (e._isAMomentObject) {
      formValue[props] = e.format()
    }
    else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }


  }


  return (
    <>
      <span className={classes.dispositionElement, "dispositions-label"}>

        <input type="radio" id={"rdo" + item._id} className="rdoDisposition" value={item._id} name={"disposition-" + item.parentId} onChange={handleSelect}
          parent={item.parentId}
        />
        <label for={'rdo' + item._id} className={classes.label}>{item.disposition_value}</label>
      
      </span>

      {(show) ? <div>Loading...</div> : null}

      {(showControls && customFields && customFields.length > 0) ?
        <span>

         
          {customFields.map((item) => {
            return <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText={item.custom_field_name}
                id={item.custom_field_name}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: ['radio', 'checkbox'].indexOf(item.field_type) > -1 ? false : true }}
                customOptions={item.field_options}
                inputProps={{
                  required: item.required,
                  name: item.custom_field_name,
                  type: item.field_type,
                  onChange: handleChange
                }}
              />
            </GridItem>


          })}
        </span> : null}

      {item.child && item.child.length > 0 && <ul className="dispositions hide" id={item._id}>
        {item.child.map((item) => {
          return <li className={classes.disposition}>
            <CreateControl item={item} onSelect={onSelect} classes={classes} showControls={showControls} />
          </li>
        })}
      </ul>

      }

      {/* {(childDisposition && childDisposition.length > 0) ? <ul className="dispositions" id={item._id}>
        {childDisposition.map((item) => {
          return <li className={classes.disposition}>
            <CreateControl item={item} onSelect={onSelect} classes={classes} showControls={showControls} />
          </li>
        })}
      </ul> : null} */}
    </>
  )
}




function ViewDisposition(props) {

  const { classes, assignTo , onChangeAssignTo, baseProspects,campaign, user, showControls, dispositions, className, Title, showRemarks, onSelectDisposition, onRemarksChange, onChangeCallback, onChangeAppointment } = props;
  const [stateDispositions, setStateDispositions] = useState([]);
  const [allDispositions, setAllDispositions] = useState([]);

  const [remarks, setRemarks] = useState([]);
  const [selectedDisposition, setSelectedDisposition] = useState([]);
  const [, setAllCustomFields] = useState([]);
  const [showCallback, setShowCallback] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [assignOption, setassignOption] = useState(null);



  const getChild = (data, parentId) => {
    let returnVal = [];
    if (data && data.length > 0) {
      let _customFields = data;
      for (let index = 0; index < _customFields.length; index++) {
        const element = _customFields[index];
        if (element.parentId == parentId) {
          element['child'] = getChild(data, element._id)
          returnVal.push(element)
        }
      }
    }
    return returnVal;
  }

  const onSelect = ({ e, item, selected }) => {

    console.log(e);
    setSelectedDisposition(selected);

    if (onSelectDisposition) {

      let obj = []
      selected.forEach(element => {
        let elem = _.where(allDispositions, { _id: element });
        if (elem && elem.length > 0) {

          let data = { ...elem[0] }
          if (data.child && data.child.length > 0) {
            data.hasChild = true
          }
          else {
            data.hasChild = false
          }
          delete data.child;
          obj.push(data);
        }
      });

      //onSelectDisposition(allDispositions.map(x => x._id))
      onSelectDisposition(obj);
    }

    if (!showControls) {
      return;
    }
    //Callback,Appointment
    if (item.action.indexOf('Callback') > -1) {
      setShowCallback(true);
    }
    else {
      setShowCallback(false);
    }
    if (item.action.indexOf('Appointment') > -1) {
      setShowAppointment(true);
    }
    else {
      setShowAppointment(false);
    }

  }

  const dispositionNode = (item) => {
    return <li className={classes.disposition}>
      <CreateControl item={item} onSelect={(item) => onSelect(item)} classes={classes} showControls={showControls} />
    </li>
  }

  const dispositionList = (dispositions) => {
    return <ul className="dispositions">
      {
        dispositions.map((item) => {
          return dispositionNode(item);
        })
      }</ul>
  }
  const loadAllDisposition = async () => {

    let registerRequest;

    // if (localStorage.getItem("allDispositions") === null) {

    // }
    // else {
    //   let allDisposition = JSON.parse(localStorage.getItem("allDispositions"))
    //   //let disposition = _.where(allDisposition, { parentId: null });

    //   setStateDispositions(disposition);
    //   return;
    // }

    try {
      registerRequest = await API_POST("action/getdata",
        {
          root: 'dispositions',
          con: { admin: user && user.admin },
          cols: {}
        });

      if (registerRequest && registerRequest.status == 200) {

        let disposition = registerRequest.data;
        setAllDispositions(disposition);

        let res = getChild(disposition, null);
        setStateDispositions(res);
        //localStorage.setItem("allDispositions", JSON.stringify(registerRequest && registerRequest.data))
      }
    } catch (error) {
      console.log(error)
      //registerRequest = response;
    }

  }
  const loadAllCustomFields = async () => {
    debugger
    let registerRequest;

    if (localStorage.getItem("allCustomFields") === null) {

    }
    else {
      let allCustomFields = JSON.parse(localStorage.getItem("allCustomFields"))
      setAllCustomFields(allCustomFields);
      return;
    }

    try {
      registerRequest = await API_POST("action/getdata",
        {
          root: 'custom_forms_new',
          con: { admin: user && user.admin },
          cols: {}
        });

      if (registerRequest && registerRequest.status == 200) {
        setAllCustomFields(registerRequest && registerRequest.data);
        localStorage.setItem("allCustomFields", JSON.stringify(registerRequest && registerRequest.data))
      }
    } catch (error) {
      console.log(error)
      //registerRequest = response;
    }

  }

  useEffect(() => {
    if (dispositions) {
      //let _dispositions = getChild(dispositions, null);
      //setStateDispositions(_dispositions);
      let toDisplay = [];
      stateDispositions.forEach(all => {
        dispositions.forEach(selected => {

          if (selected._id == all._id) {
            toDisplay.push(all);
          }
        });
      });
      setStateDispositions(toDisplay);

    }
  }, [dispositions]);

  useEffect(() => {
    if (user) {
      loadAllDisposition();
      loadAllCustomFields();
    }
  }, [user]);

  useEffect(()=>{

    console.log("campppppppppppppppp",campaign)

    if(campaign && campaign.length){
      let op=[];
         campaign[0].campaignMembers.map((v)=>{
          op.push({key:v,value:v == user.email ? "Assigned Me" : v})
         })
         setassignOption(op)
    }

  },[campaign])

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
    onRemarksChange(e.target.value);
  }
  const handleChangeCallback = (e) => {

    if (onChangeCallback)
      onChangeCallback(e);
  }

  const handleChangeAssignee = (e) => {

    if (onChangeAssignTo)
    onChangeAssignTo(e);
  }

  const handleChangeAppointment = (e) => {
    if (onChangeAppointment)
      onChangeAppointment(e);

  }

  return (
    <Card className={className}>
      <Box bgcolor="#dcd6f7">
        <CardHeader>
          <h3>{Title}</h3>
        </CardHeader>
      </Box>
      <CardBody>
        <form id="frmDisposition" className="scroller">

          {(stateDispositions && stateDispositions.length > 0) ? dispositionList(stateDispositions) : <Loader />}
        </form>
      </CardBody>







      {showCallback && <CardFooter><CallbackControl onChangeCallback={(item) => handleChangeCallback(item)} Title="Schedule Callback" /></CardFooter>}
      {showAppointment && <CardFooter><CallbackControl onChangeCallback={(item) => handleChangeAppointment(item)} Title="Schedule Appointment" /></CardFooter>}

      <CardFooter>
        

        {showRemarks && <TextField
          id="outlined-multiline-static"
          multiline
          label="Remarks"
          rows={3}
          value={remarks}
          onChange={handleRemarksChange}
          variant="outlined"
          style={{ width: '100%' }}
        />}
      </CardFooter>
      { assignOption && <CardFooter style={{ marginTop:"-31px",border: 0,borderStyle:"groove",paddingTop:0 }}>
        <GenerateField
          value={assignTo}
          onChange={handleChangeAssignee}
          id={"enableAssignFromCallPage"}
          label={""}
          type="select"
          disabled={(campaign && campaign.length && campaign[0]['enableAssignFromCallPage']) ? false : true}
          //options={assignOption}
          keyValuePair={assignOption}
          
        />      
        </CardFooter>}
      
    </Card>





  );
}


export default withStyles(style)(ViewDisposition);


class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
